import rtm from '@/service/realTimeMessages';
import RTMInstance from 'vimmi-web-utils/esm/realTimeMessages';
import ShopPlayerApi from '@/shared/api/shop-player.api';
import ShoprzStore from '@/store/shoprz';
import Auth from '@/service/authService';
import {
  disablePageScroll,
  enablePageScroll,
} from '@/shared/helpers/pageScrollToggler';
import { amsClient } from '@/service/ams';
import SharedProductAPI from '@/shared/api/product/product.api';
import smoothScroll from '@/shared/helpers/smooth-scroll';
import { mapActions, mapGetters, mapState } from 'vuex';
import device from '@/service/device-service';
import { PLAYER_EVENTS } from 'shop-player-v2/dist/shop-player-v2';
import RocketChatClient from '@/core/service/rocket-chat.service';
import ShopPlayerService from '@/shared/services/shop-player.service';
import { memoryStore } from 'vimmi-web-utils/esm/memoryStore';
import { makeId } from 'vimmi-web-utils/cjs/makeId';

const rc = new RocketChatClient();

export default {
  data() {
    return {
      rtmActiveRoom: null,
      rtmLiveSubscribers: [],
      shopOffers: {},
      inFullScreen: false,
      inFullScreenEmulated: false,
      itemsActiveProducts: {},
      itemsProducts: {},
      itemsChannelsFollowState: {},
      itemsStats: {},
      itemsOverlaysState: {},
      shopPlayerPlaybackState: null,
      productPopupDetails: null,
      chatUserLoading: false,
      rewindToProduct: {},
      playTemplateReadyStack: [],
      chatConfig: {
        backendUrl: amsClient.get('conf.chat.chat_backend_url'),
      },
      logoLink: amsClient.get('conf.store.logo'),
      shopPlayerTagClasses: this.calcShopPlayerTagClasses(),
      specificLiveRTMInstance: null,
      positionPercent: {},
      viewersLimitReached: {},
    };
  },
  computed: {
    ...mapGetters({
      getPlayTemplate: 'chachedPreviewMedia/getPlayTemplate',
    }),
    ...mapState('floatPlayer', {
      floatItem: 'floatItem',
      floatOptions: 'options',
      floatPlaybackState: 'playbackState',
    }),
    ...mapState('popupPlayer', {
      vodPopupItem: 'item',
      vodPopupPlaybackState: 'playbackState',
    }),
    shopPlayerSpecialClasses() {
      let classes = 'with-shop-player';
      if (device.isAnyMobile()) {
        classes += ' with-shop-player--mobile';
      }
      if (this.inFullScreen && this.shopPlayerFullPageMode) {
        classes += ' with-shop-player-fullscreen';
        if (this.inFullScreenEmulated) {
          classes += ' with-shop-player-fullscreen--emulated';
        }
      }
      return classes;
    },
  },
  watch: {
    getPlayTemplate: {
      handler(newVal) {
        if (newVal) {
          while (this.playTemplateReadyStack.length) {
            this.playTemplateReadyStack.pop()(true);
          }
        }
      },
      immediate: true,
    },
    floatPlaybackState: {
      handler(newVal) {
        if (this.$refs.shopPlayer && !newVal.paused && ((this.shopPlayerPlaybackState && !this.shopPlayerPlaybackState.paused) || (this.vodPopupPlaybackState && !this.vodPopupPlaybackState.paused))) {
          this.$refs.shopPlayer.pause(null, false);
        }
      },
    },
    shopPlayerActiveItem: {
      handler: function handler(newItem, oldItem) {
        if (oldItem?.id) {
          delete this.positionPercent[oldItem?.id];
        }
        this.closeProductsDetailsPopup();
        if (newItem) {
          if (newItem?.creator) {
            this.$set(
              this.itemsChannelsFollowState,
              newItem.id,
              ShoprzStore.isChannelFollowed(newItem.creator.id),
            );
          }
          this.handleShopOffer(this.shopPlayerActiveItem);
        }
        this.disconnectFromLiveRoom();
        if (newItem?.id && newItem?.is_live) {
          this.connectToLiveRoom(newItem?.id);
        } else {
          this.connectLobby();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadChatUser();
    this.$bus.$on('changedUserState', async ({ type }) => {
      if (type === 'logged') {
        await this.loadChatUser();
      } else if (type === 'logout') {
        await this.loadChatUser();
      }
    });
    document.addEventListener('scroll', this.onMobileDocumentScroll);
    document.addEventListener('visibilitychange', this.onVisibilityChange);
    this.shopPlayerTagClasses = this.calcShopPlayerTagClasses();
  },
  mounted() {
    this.shopPlayerTagClasses = this.calcShopPlayerTagClasses();
  },
  beforeRouteUpdate(to, from, next) {
    this.closeProductsDetailsPopup();
    next();
  },
  beforeDestroy() {
    this.closeProductsDetailsPopup();
    document.removeEventListener('scroll', this.onMobileDocumentScroll);
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    if (this.inFullScreen) {
      this.shopPlayerFullScreenChanged({ active: false, emulated: false });
    }
  },
  methods: {
    ...mapActions({
      setFloatItem: 'floatPlayer/setFloatItem',
      setFloatPlaybackState: 'floatPlayer/setPlaybackState',
      setFloatOptions: 'floatPlayer/setFloatOptions',
    }),
    showLoginPopup() {
      this.$bus.$emit('toggleAuthPopup', {
        component: 'login',
        forceCloseBtn: true,
      });
    },
    onMobileDocumentScroll() {
      this.shopPlayerTagClasses = this.calcShopPlayerTagClasses();
    },
    onVisibilityChange() {
      if (!document.hidden && device.isAnyMobile()) {
        if (this.shopPlayerActiveItem.is_live) {
          // todo:
          this.$refs.shopPlayer.seekTo(
            null,
            99999999,
          );
        }
      }
    },
    async loadChatUser() {
      console.log('[chat][loadChatUser]', this.chatUserLoading);
      if (this.chatUserLoading) {
        return;
      }
      this.chatUserLoading = true;

      const localChatUser = amsClient.get('user.chat_user');

      const isAuth = Auth.isAuth() && !Auth.isGuest();

      if (localChatUser && localChatUser.itype === 'chat_user') {
        this.$set(this.chatConfig, 'user', {
          ...localChatUser,
          name: isAuth ? amsClient.get('user').name : localChatUser.name,
        });
        this.chatUserLoading = false;
        return;
      }

      const uid = rc.getIdForChatUser(isAuth);
      try {
        const chatUser = await rc.getChatUser(uid);

        if (!isAuth) {
          this.$set(this.chatConfig, 'needResetUsername', true);
        }

        this.$set(this.chatConfig, 'user', chatUser);
      } catch (err) {
        console.log('err', err);
      }
      this.chatUserLoading = false;
    },
    async chatUserUpdated({ name }) {
      await amsClient.account.update({ name });
      amsClient.set('user.chat_user.name', name);
      if (name === 'guest__change_nickname') {
        this.$set(this.chatConfig, 'needResetUsername', false);
      }
    },
    async onProductClick({ product }) {

      const productLink = (product.extern_shop || '');
      const isExternalProduct = productLink.includes('https://');
      const isMagentoProduct = (product.source || '').toLowerCase() === 'magento';

      if (!isMagentoProduct && isExternalProduct) {
        window.open(productLink, '_blank').focus();
      } else if (product.remote_id) {
        this.productPopupDetails = null;

        const response = await SharedProductAPI.getProductById(product.remote_id);

        this.productPopupDetails = response.product;

        if (this.productPopupDetails) {
          this.productPopupDetails.amsProduct = product;
          await smoothScroll(document.documentElement, {
            top: 0,
            duration: 100,
          });
        }
      }

      this.shopPlayerTagClasses = this.calcShopPlayerTagClasses();
    },
    closeProductsDetailsPopup() {
      this.productPopupDetails = null;
      try {
        if (this.$refs.shopPlayer) {
          this.$refs.shopPlayer.closeProductsDetailsPopup(true);
        }
      } catch (e) {
        console.log(e);
      }
      this.shopPlayerTagClasses = this.calcShopPlayerTagClasses();
    },
    sendReaction(reaction) {
      this.sendRtm('reaction', reaction);
    },
    openSharePopup({ item, link }) {
      if (this.inFullScreen) {
        this.$refs.shopPlayer.$emit('toggle-fullscreen');
      }
      this.$bus.$emit('ToggleSharingDialog', {
        currentStoreLink: link || `${this.$location}/watch/${item.slug}`,
        currentStoreName: (item.title || '').replace('Event for: ', ''),
        item: item,
      });
    },
    openStorePage({ item }) {
      const slug = (item?.storefront || item?.store || item?.creator || {}).slug;
      this.$router.push({ path: `/s/${slug}` });
    },
    shopPlayerFullScreenChanged({ active, emulated }) {
      this.shopPlayerFirstClick = true;
      this.inFullScreenEmulated = emulated;
      this.inFullScreen = active;
      if (this.shopPlayerFullPageMode) {
        if (active && emulated) {
          this.$bus.$emit('handleSpecificClass', this.shopPlayerSpecialClasses);
          this.$bus.$emit('handleHeaderSpecificClass', 'd-none');
        } else {
          this.$bus.$emit('handleSpecificClass', this.shopPlayerSpecialClasses);
          this.$bus.$emit('handleHeaderSpecificClass', 'shop-player-header');
        }
      }

    },
    blockPageScroll({ whiteList = [], component }) {
      disablePageScroll(component, [...(whiteList || []), 'product-details-popup__content']);
    },
    unBlockPageScroll({ component }) {
      enablePageScroll(component);
    },
    async toggleFollow(state) {
      const { itemId } = state;

      let creatorId = null;
      let item = null;

      if (state.creatorId) {
        creatorId = state.creatorId;

        item = {
          id: itemId,
          creator: {
            id: creatorId,
          },
        };
      }

      if (state.item) {
        item = {
          ...state.item,
          creator: {
            id: state.item.storefront.id,
          },
        };

        creatorId = state.item.storefront.id;
      }

      if (!Auth.isAuth() || Auth.isGuest()) {
        if (this.inFullScreen) {
          this.$refs.shopPlayer.$emit('toggle-fullscreen');
        }
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          forceCloseBtn: true,
        });
        return;
      }
      if (ShoprzStore.isChannelFollowed(creatorId)) {
        this.$stats.send('channel_unfollow', {}, { item });
      } else {
        this.$stats.send('channel_follow', {}, { item });
      }
      await ShoprzStore.toggleFollow(creatorId);

      this.$set(
        this.itemsChannelsFollowState,
        itemId,
        ShoprzStore.isChannelFollowed(creatorId),
      );
    },
    async handleShopOffer(item) {
      if (item?.shopoffer) {
        const shopOffer = await ShopPlayerApi.getShopOffer(item.shopoffer);
        this.$set(this.shopOffers, item.id, shopOffer);
        this.$set(this.itemsProducts, item.id, shopOffer.items);
        return true;
      }
      return false;
    },
    async connectToLiveRoom(roomId) {
      if (this.specificLiveRTMInstance && this.specificLiveRTMInstance.isActive() && this.rtmActiveRoom === roomId) {
        return;
      }
      this.rtmActiveRoom = roomId;
      this.specificLiveRTMInstance = new RTMInstance();
      if (process.env.NODE_ENV === 'development') {
        this.specificLiveRTMInstance.setDomain('et-ut-unde.dev-shoprz.live');
      } else {
        this.specificLiveRTMInstance.setDomain(location.host);
      }
      this.watchLiveRTM();
      await this.specificLiveRTMInstance.init(roomId, null, {
        cleanSubscribes: false,
      });
    },
    disconnectFromLiveRoom() {
      if (!this.specificLiveRTMInstance) {
        return;
      }

      this.specificLiveRTMInstance.disconnect();
      this.specificLiveRTMInstance.off(this.rtmLiveSubscribers);
      this.specificLiveRTMInstance = null;
    },
    async connectLobby() {
      if (rtm.isActive() && this.rtmActiveRoom === 'lobby') {
        return;
      }
      rtm.off(this.rtmLiveSubscribers);
      if (!this.rtmActiveRoom) {
        // to skip initial connect which done from App.vue
        this.rtmActiveRoom = 'lobby';
        return;
      }
      // console.trace('connectLobby', rtm.isActive(), this.rtmActiveRoom);
      this.rtmActiveRoom = 'lobby';
      await rtm.init('lobby', null, {
        cleanSubscribes: false,
      });
    },
    sendRtm(command, msg) {
      if (this.rtmActiveRoom === this.shopPlayerActiveItem.id) {
        this.specificLiveRTMInstance.send(command, msg);
      }
    },
    watchLiveRTM() {
      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('overlay_product', async (data) => {
          if (
            !this.shopPlayerActiveItem
            || !this.shopPlayerActiveItem.is_live
          ) {
            return;
          }
          setTimeout(async () => {
            const products = this.itemsProducts[this.shopPlayerActiveItem.id] || [];
            const product = products.find(
              (product) => product.b_id === data.msg,
            );
            if (data.msg && (!product || !product.time_in)) {
              // show
              await this.handleShopOffer(this.shopPlayerActiveItem);
              this.$set(
                this.itemsActiveProducts,
                this.shopPlayerActiveItem.id,
                data.msg,
              );
            } else if (
              !data.msg
              && this.itemsActiveProducts[this.shopPlayerActiveItem.id]
            ) {
              // hide
              await this.handleShopOffer(this.shopPlayerActiveItem);
              delete this.itemsActiveProducts[this.shopPlayerActiveItem.id];
            }
          }, 1000);
        }),
      );
      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('stats', (data) => {

          if (!this.shopPlayerActiveItem || !this.shopPlayerActiveItem.is_live) {
            return;
          }


          const stats = data.msg;

          Object.keys(stats).forEach((itemId) => {
            if (itemId !== 'lobby') {
              const itemState = {
                ...(this.itemsStats[itemId] || {}),
              };
              itemState.onlineViewers = stats[itemId];
              this.$set(this.itemsStats, itemId, itemState);
              this.$set(this.viewersLimitReached, itemId, itemState?.onlineViewersLimit && itemState.onlineViewers >= itemState?.onlineViewersLimit);
            }
          });
        }),
      );

      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('license_package', (data) => {
          if (
            !this.shopPlayerActiveItem
            || !this.shopPlayerActiveItem.is_live
            || data.room !== this.shopPlayerActiveItem.id
          ) {
            return;
          }
          const itemState = {
            ...(this.itemsStats[this.shopPlayerActiveItem.id] || {}),
          };
          itemState.onlineViewersLimit = data.msg.attendees_limit;
          this.$set(this.itemsStats, this.shopPlayerActiveItem.id, itemState);

        }),
      );
      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('overlay_text', (data) => {
          if (
            !this.shopPlayerActiveItem
            || !this.shopPlayerActiveItem.is_live
          ) {
            return;
          }
          const itemState = {
            ...(this.itemsStats[this.shopPlayerActiveItem.id] || {}),
          };
          itemState.overlayText = data.msg;
          this.$set(this.itemsStats, this.shopPlayerActiveItem.id, itemState);
        }),
      );
      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('overlay_status', (data) => {
          if (
            !this.shopPlayerActiveItem
            || !this.shopPlayerActiveItem.is_live
          ) {
            return;
          }
          const overlaysState = data.msg;
          // this.setState(this.shopPlayerActiveItem.id, 'overlaysState', overlaysState);
          this.$set(
            this.itemsOverlaysState,
            this.shopPlayerActiveItem.id,
            overlaysState,
          );
        }),
      );

      /** todo: */
      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('reaction', (data) => {
          if (this.$refs.shopPlayer) {
            if (Array.isArray(this.$refs.shopPlayer)) {
              this.$refs.shopPlayer[0].$emit('show-reaction', data.msg);
            } else {
              this.$refs.shopPlayer.$emit('show-reaction', data.msg);
            }
          }
        }),
      );

      this.rtmLiveSubscribers.push(
        this.specificLiveRTMInstance.on('error', (data) => {
          console.log('RTM error', data);
          if (data.room === this.shopPlayerActiveItem.id && data.msg && data.msg.toLowerCase().includes('capacity exceeded')) {

            this.$set(this.viewersLimitReached, this.shopPlayerActiveItem.id, true);
          }
        }),
      );
    },
    async getPlaybackUrl(item, password) {
      let playback_url = null;

      try {
        if (password) {
          const url = `/shoprz/play_event/${item.id}/?d=web&password=${password}`;
          const res = await amsClient.callAms(url, { cache: true });
          playback_url = res.head.media;
          memoryStore.set(`${item.id}_playback_url`, playback_url);
        } else {
          const { media } = await amsClient.callAms(`/play/${item.id}`, { cache: true });
          playback_url = media[0].link;
        }

        if (playback_url) {
          if (this.shopPlayerFullPageMode) {
            if (item.tab_id) {
              this.updateItem({ uniq_id: item.uniq_id, playback_url }, item.tab_id);
            }
            if (this.activeTab) {
              const index = this.tabsItems[this.activeTab.id].findIndex((_item) => _item.id === item.id);

              if (index >= 0) {
                const _item = this.tabsItems[this.activeTab.id][index];
                const list = this.tabsItems[this.activeTab.id].with(index, { ..._item, playback_url });
                this.$set(this.tabsItems, this.activeTab.id, list);
                this.shopPlayerActiveItem.playback_url = playback_url;
              }
            }
          } else {
            this.$set(this.shopPlayerActiveItem, 'playback_url', playback_url);
          }
        }
      } catch (err) {
        throw err;
      }

      return playback_url;
    },
    async loadFullItem(item) {
      if (!this.getPlayTemplate?.info) {
        await this.onPlayTemplateReady();
      }

      const fullItem = await ShopPlayerService.loadItem(item);
      fullItem.uniq_id = this.generateUniqIdForPlayer(fullItem);
      const playbackUrl = ShopPlayerService.generatePlaybackUrl(fullItem, this.getPlayTemplate);
      if (playbackUrl) {
        fullItem.playback_url = playbackUrl;
      }
      this.updateItem(fullItem, fullItem.tab_id);
      return fullItem;
    },
    onTimeUpdate(event, videoItem, { state }) {
      if (this.shopPlayerActiveItem?.id !== videoItem?.id) {
        return;
      }

      const duration = videoItem.is_live && state.duration === Infinity ? state.liveTargetDuration || state.duration || 0 : state.duration;

      const percent = Math.floor((state.position * 100) / duration);
      const endedPercent = duration > 20 ? 98 : 95;
      const positionPercent = this.positionPercent[videoItem.id] || [0, 0, 0, 0, 0, 0];
      const percentThresholds = [0, 10, 25, 50, 75, endedPercent];

      for (let i = percentThresholds.length - 1; i >= 0; i--) {
        if (percent >= percentThresholds[i]) {
          if (!positionPercent[i]) {
            positionPercent[i] = 1;
            if (i === 0) {
              this.sendProgressEvent(videoItem, state, 0);
            } else if (i === percentThresholds.length - 1) {
              this.sendProgressEvent(videoItem, state, 100);
            } else if (!videoItem.is_live) {
              this.sendProgressEvent(videoItem, state, percentThresholds[i]);
            }
          }
          break;
        }
      }

      this.positionPercent[videoItem.id] = positionPercent;
    },
    async sendProgressEvent(video, state, percent) {
      let videoItem = { ...video };
      const eventName = (percent === 0) ? 'videoStart' : ((percent === 100) ? 'videoComplete' : 'videoProgress');

      this.$stats.send(eventName,
        this.getGTMEventBody(eventName, videoItem, state, !videoItem.is_live ? { video_percent: percent } : {}),
        {
          only: ['gtm'],
        });
    },


    getGTMEventBody(eventName, videoItem, state, additionalData = {}) {
      const duration = videoItem.is_live && state.duration === Infinity ? state.liveTargetDuration || state.duration || 0 : state.duration;
      return {
        video_current_time: eventName === 'videoComplete' ? Math.floor(duration) : Math.floor(state.position),
        video_duration: Math.floor(duration),
        video_provider: 'Vimmi',
        video_title: videoItem.movie_title || videoItem.title,
        video_type: videoItem.movie_id ? 'item_mov_vod_shoprz' : videoItem.itype,
        video_url: top.location.href,
        visible: 1,
        video_id: videoItem.movie_id || videoItem.id,
        channel_id: this.$stats.getChannelId(videoItem),
        channel_title: this.$stats.getChannelTitle(videoItem),
        store_id: this.$stats.getStoreId(videoItem),
        store_title: this.$stats.getStoreTitle(videoItem),
        shoppable: !!this.itemsProducts?.[videoItem.id]?.length,

        ...additionalData,
      };
    },

    /**
     * @param {Object<data, item, event>}
     * @param type {'main', 'popup', 'float'}
     *
     * * */
    shopPlayerStats({ event, item, data }, type = null) {
      const playerEvent = event.replace('player:', '');

      // console.log('[stats][main]', item.id, event, data.state, this.$stats.getCollectedData(item?.id, 'watch'));
      switch (event) {
        case 'player:commented':
          this.$stats.send(
            'commented',
            {
              comment_id: data._id,
              room_id: data.rid,
            },
            { item },
          );
          break;
        case 'player:user-play':
          // this.$stats.collect(item.id, 'watch', playerEvent, data.state);
          break;
        case 'player:user-pause':
          // this.$stats.collect(item.id, 'watch', playerEvent, data.state);
          break;
        case 'player:user-resume':
          // this.$stats.collect(item.id, 'watch', playerEvent, data.state);
          break;
        case PLAYER_EVENTS.TIME_UPDATE:
          this.onTimeUpdate('update', item, data);

          const heartbeatData = this.$stats.collect(
            item.id,
            'heartbeat',
            playerEvent,
            data.state,
            type,
          );
          if (heartbeatData) {
            this.$stats.send('heartbeat', { ...heartbeatData }, { item });
          }

          this.$stats.collect(item.id, 'watch', playerEvent, data.state);
          break;
        case PLAYER_EVENTS.SEEKED:
          this.$stats.collect(item.id, 'watch', playerEvent, data.state);
          break;
        case PLAYER_EVENTS.ENDED:
          if (data.state.watchTime >= 1) {
            const heartbeatData = this.$stats.collect(
              item.id,
              'heartbeat',
              playerEvent,
              data.state,
              type,
            );
            if (heartbeatData) {
              this.$stats.send('heartbeat', { ...heartbeatData }, { item });
            }
            this.$stats.send('watch', {}, { item });
          }
          break;
        case 'player:destroyed':
          if (
            ((this.floatOptions?.type === 'vod-popup' && type === 'popup')
              || this.floatOptions?.type !== 'vod-popup')
            && this.floatItem?.id === item?.id
          ) {
            // item plays in float mode
            // do nothing
          } else if (data.state.watchTime >= 1) {
            this.$stats.send('watch', {}, { item });

            const heartbeatData = this.$stats.collect(
              item.id,
              'heartbeat',
              'ended',
              data.state,
              type,
            );
            if (heartbeatData) {
              this.$stats.send('heartbeat', { ...heartbeatData }, { item });
            }

            this.$stats.clearCollectedData(item.id, 'heartbeat');
          }
          break;
      }
    },
    onPlayTemplateReady() {
      return new Promise((resolve) => {
        if (this.getPlayTemplate?.info) {
          resolve(true);
        } else {
          this.playTemplateReadyStack.push(resolve);
        }
      });
    },
    hideFloatPlayer() {
      this.setFloatItem(null);
      this.setFloatOptions(null);
      this.setFloatPlaybackState(null);
    },
    updateItem(item, tabId) {
      if (tabId && this.tabsItems[tabId]) {
        const index = this.tabsItems[tabId].findIndex((_item) => _item.id === item.id);
        if (this.tabsItems[tabId][index]) {
          this.$set(this.tabsItems[tabId], index, { ...this.tabsItems[tabId][index], ...item });
        }
      }
    },
    canPlay(item) {
      if (!item) {
        return true;
      }
      return amsClient.canPlay(item);
    },
    generateUniqIdForPlayer(item) {
      return (item.is_live || item.otype === 'live') ? (item.event_slug || item.slug) : (item.uniq_id || makeId(12));
    },
    calcShopPlayerTagClasses() {
      let classes = '';

      if (device.isAnyMobile() && document.scrollingElement && document.scrollingElement?.scrollTop <= 1) {
        classes += ' shop-player--fixed';
        if (device.isAnyMobile()) {
          if (device.isIOS() && device.isChrome()) {
            classes += ' ios--chrome';
          }
        }
      } else {
        classes += ' shop-player--relative';
        if (!device.isAnyMobile()) {
          classes += ' desktop';
        }
      }
      if (this.productPopupDetails) {
        classes += ' product-popup--active';
      }
      return classes;
    },

    /**
     * @param {Object} playerState
     * @param {Boolean} playerState.chat
     * @param {Number} playerState.chatHeight
     * @param {Boolean} playerState.productDrawer
     * @param {Number} playerState.productDrawerHeight
     * @param {Boolean} playerState.isEmulatedFullScreen
     * @param {Boolean} playerState.isMobile
     * @param {Boolean} playerState.isEmbedMode
     * @param {isPrivate} playerState.isPrivate
     * @param {'landscape' | 'portrait'} playerState.screenMode
     * */
    resizeArea(playerState) {
      let player = this.$refs.shopPlayer;
      // console.log(1111111, player, playerState);
      if (player && playerState.isMobile) {
        let productDrawerHeight = playerState.productDrawer ? playerState.productDrawerHeight || 73 : 0;
        let chatHeight = playerState.chat ? (playerState.chatHeight || 200) : 0;
        let rect = player.$el.getBoundingClientRect();
        player.$el.parentNode.style.paddingTop = 'unset';
        if (playerState.isVertical || playerState.isPrivate) {
          let height = parseInt(rect.width) * (16 / 9);
          height = window.innerHeight < height ? window.innerHeight * 0.9 : height;
          player.$el.style.height = height + 'px';
          player.$el.parentNode.style.height = height + 'px';
        } else {
          let height = (parseInt(rect.width) * (9 / 16) + productDrawerHeight + chatHeight);
          height = window.innerHeight < height ? window.innerHeight * 0.9 : height;
          player.$el.style.height = height + 'px';
          player.$el.parentNode.style.height = height + 'px';
        }
      } else {
        if (player.$el.parentNode.style.paddingTop === 'unset') {
          player.$el.parentNode.style.removeProperty('padding-top');
          player.$el.parentNode.style.removeProperty('height');
          player.$el.style.removeProperty('height');
        }
      }
    },
  },
};